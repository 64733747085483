import React, { lazy, Suspense } from "react";
import { HelmetProvider } from "react-helmet-async";
import { IntlProvider } from "react-intl";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import {
  CssBaseline,
  MuiThemeProvider,
  LinearProgress,
} from "@material-ui/core";

import useBrowserLocale from "./hooks/useBrowserLocale";
import theme, { GlobalCss, WebkitCss } from "./theme";

const About = lazy(() => import("./pages/About"));
const Farm = lazy(() => import("./pages/Farm"));
const Gallery = lazy(() => import("./pages/Gallery"));
const Info = lazy(() => import("./pages/Info"));
const Lavender = lazy(() => import("./pages/Lavender"));
const LavenderSalt = lazy(() => import("./pages/LavenderSalt"));
const Wool = lazy(() => import("./pages/Wool"));
const Offer = lazy(() => import("./pages/Offer"));

const supportedLocales = ["pl"];

const App = () => {
  const locale = useBrowserLocale(supportedLocales);
  const messages = require(`./messages/messages.${locale}.json`);

  return (
    <HelmetProvider>
      <MuiThemeProvider theme={theme}>
        <IntlProvider locale={locale} messages={messages}>
          <CssBaseline />
          <GlobalCss />
          <WebkitCss />
          <Suspense fallback={<LinearProgress />}>
            <BrowserRouter>
              <Routes>
                <Route element={<About />} path="/"></Route>
                <Route element={<Farm />} path="/farm"></Route>
                <Route element={<Wool />} path="/wool"></Route>
                <Route element={<Lavender />} path="/lavender"></Route>
                <Route element={<LavenderSalt />} path="/lavender-salt"></Route>
                <Route element={<Gallery />} path="/gallery"></Route>
                <Route element={<Offer />} path="/offer"></Route>
                <Route element={<Info />} path="/info"></Route>
                <Route element={<Navigate to="/" />} path="*"></Route>
              </Routes>
            </BrowserRouter>
          </Suspense>
        </IntlProvider>
      </MuiThemeProvider>
    </HelmetProvider>
  );
};

export default App;
