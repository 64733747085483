import { createTheme, withStyles } from "@material-ui/core";
import { blueGrey, grey, red } from "@material-ui/core/colors";

export const optimalSize = 700;

const theme = createTheme({
  palette: {
    primary: red,
    secondary: blueGrey,
    background: {
      default: "white",
    },
  },
  typography: {
    body1: {
      lineHeight: 1.75,
    },
    fontFamily: [
      "Lato",
      "Ubuntu",
      "Helvetica Neue",
      "Segoe UI",
      "Roboto",
      "Arial",
      "sans-serif",
    ].join(","),
  },
});

theme.typography.h1 = {
  ...theme.typography.h1,
  fontSize: "4rem",
  fontWeight: 500,
  [theme.breakpoints.up("md")]: {
    fontSize: "4.5rem",
  },
};

theme.typography.h3 = {
  ...theme.typography.h3,
  fontWeight: 500,
};

theme.typography.h4 = {
  ...theme.typography.h4,
  fontWeight: 400,
};

theme.typography.h5 = {
  ...theme.typography.h5,
  fontWeight: 400,
};

export const GlobalCss = withStyles({
  "@global": {
    body: {
      height: "100vh",
      backgroundColor: grey[50],
    },
    html: {
      height: "100vh",
    },
    "#root": {
      height: "100%",
    },
    "p.paragraph, div.paragraph": {
      fontSize: theme.typography.body1.fontSize,
      maxWidth: optimalSize,
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    a: {
      color: "inherit",
      textDecoration: "none",
    },
  },
})(() => null);

export const WebkitCss = withStyles({
  "@global": {
    body: {
      height: "-webkit-fill-available",
    },
    html: {
      height: "-webkit-fill-available",
    },
  },
})(() => null);

export default theme;
